<template>
  <img
    v-if="userStore.isAuthenticated() || !!name"
    :src="`https://ui-avatars.com/api/?name=${
      name ?? userStore.user.email
    }&color=fff&background=FF4176`"
    :alt="name ?? userStore.user.email"
    v-bind="$attrs"
  />
  <span v-else>
    <nuxt-icon class="nii-user-icon" name="user" filled />
  </span>
</template>

<script lang="ts">
import { useUserStore } from '~/stores'
import { useViewTools } from '~/composables'

export default {
  name: 'NameInitialsImage',
  inheritAttrs: false,
  props: {
    name: { type: String, default: () => null },
  },
  setup() {
    const userStore = useUserStore()

    return {
      userStore,
      ...useViewTools(),
    }
  },
}
</script>

<style lang="scss">
.nii-user-icon {
  @include tablet {
    svg {
      overflow: visible;
    }

    path {
      stroke-width: 3;
    }
  }
}
</style>
